<template>
  <b-table
    ref="DescricoesTable"
    :fields="tableFields"
    :items="lote"
    class="mt-3"
    responsive
    show-empty
    empty-text="Nenhum material adicionado"
  >
    <!-- TODO hardcoded tipo material -->
    <template v-if="!opmeConsignadoLote" #thead-top>
      <b-button
        :active="true"
        variant="outline-secondary"
        class="add-descricao"
        @click="addCaixa"
        >+ Adicionar descrição</b-button
      >
    </template>
    <template v-else #custom-foot>
      <b-tr>
        <b-td :colspan="4" class="custom-foot-add-desc">
          <b-button
            :active="true"
            variant="outline-secondary"
            class="add-descricao"
            @click="addCaixa"
            >+ Adicionar descrição</b-button
          >
        </b-td>
      </b-tr>
    </template>
    <template #cell(patrimonio)="{ index }">
      <b-form-input
        :value="lote[index].patrimonio"
        :key="`patrimonio_caixa-${index}`"
        :name="`patrimonio_caixa-${index}`"
        @input="(v) => updateLote(index, 'patrimonio', v)"
        v-validate="{ required: false }"
        :state="validateState(`patrimonio_caixa-${index}`)"
      />
    </template>
    <template #cell(index)="{ index }">
      {{ indexLessThanTen(index) ? `0${index + 1}` :  `${index + 1}` }}
    </template>
    <template #cell(descricao_caixas)="{ index }">
      <b-form-group>
        <InputWithSuggestions
          route="material/materiaisPorDescricao"
          propertyName="descricao"
          id="descricao"
          :key="`descricao_caixas-${index}`"
          :name="`descricao_caixas-${index}`"
          :value="lote[index].descricao_caixas"
          @input="(v) => updateLote(index, 'descricao_caixas', v)"
          v-validate="{ required: true }"
          :state="validateState(`descricao_caixas-${index}`)"
          item-key-identifier="id_material"
          item-content-identifier="descricao"
          :min-search-length="3"
          :max-item-length="30"
        />
      </b-form-group>
    </template>
    <template #cell(state)="{ index }">
      <b-button
        class="btn btn-sm text--black"
        variant="outline-dark"
        @click="removeMaterial(index)"
      >
        <trash-2-icon class="w20px" />
      </b-button>
    </template>
  </b-table>
</template>

<script>
import InputWithSuggestions from '../../components/Form/InputWithSuggestions';

export default {
  inject: ['parentValidator'],
  created() {
    this.$validator = this.parentValidator;
  },
  components: {
    InputWithSuggestions,
  },
  props: {
    lote: {
      type: Array,
      default: () => [],
    },
    hasQntItens: {
      type: Boolean,
      default: false,
    },
    opmeConsignadoLote: {
      type: Boolean,
      default: false,
    },
    validateState: {
      type: Function,
      default: () => null,
    },
    type: {
      type: String,
      default: 'Caixa',
    },
  },

  computed: {
    tableFields() {
      if (this.hasQntItens) {
        return [
          { key: 'descricao_caixas', label: 'Descrição', sortable: false },
          { key: 'quantidade_itens', label: 'N. de Itens', sortable: false },
          { key: 'quantidade_caixas', label: `N. de ${this.type}s`, sortable: false },
          { key: 'state', label: 'Remover', sortable: false },
        ];
      }
      if (this.opmeConsignadoLote) {
        return [
          { key: 'index', label: '#', class: 'mw-40' },
          { key: 'descricao_caixas', label: 'Descrição*', sortable: false },
          { key: 'patrimonio', label: 'Patrimônio', sortable: false },
          { key: 'state', label: 'Remover', sortable: false },
        ];
      }
      return [
        { key: 'descricao_caixas', label: 'Descrição', sortable: false },
        { key: 'quantidade_caixas', label: `N. de ${this.type}s`, sortable: false },
        { key: 'state', label: 'Remover', sortable: false },
      ];
    },
  },

  methods: {
    updateLote(index, prop, value) {
      this.$emit('update-lote', [
        ...this.lote.slice(0, index),
        {
          ...this.lote[index],
          [prop]: value,
        },
        ...this.lote.slice(index + 1),
      ]);
    },
    indexLessThanTen(index) {
      return index < 9;
    },
    addCaixa() {
      if (this.opmeConsignadoLote) {
        this.$emit('update-lote', [
          ...this.lote,
          {
            descricao_caixas: '',
            patrimonio: '',
            quantidade_caixas: 1,
          },
        ]);
      } else {
        this.$emit('addCaixa');
      }
    },
    removeMaterial(indexCaixa) {
      if (this.opmeConsignadoLote) {
        this.$emit('update-lote', [
          ...this.lote.slice(0, indexCaixa),
          ...this.lote.slice(indexCaixa + 1),
        ]);
      } else {
        this.$emit('removeCaixa', indexCaixa);
      }
    },
  },
};
</script>

<style>
.add-descricao {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  margin-bottom: 5px;
}
.mw-40 {
  max-width: 40px;
}
.custom-foot-add-desc {
  padding: 0 !important;
  border-top: 0 !important;
}
</style>
