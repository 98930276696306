<template>
<div class="item" @mousedown="emitItemData">
  {{ content }}
</div>
</template>

<script>
export default {
  name: 'SuggestionItem',
  props: {
    id: {
      type: Number,
      required: false,
    },
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    emitItemData() {
      this.$emit('item-data', { id: this.id, content: this.content });
    },
  },

};

</script>

<style scoped>
  .item {
    cursor: pointer;
    padding: 15px;
    color: #413e52;
  }
  .item:hover {
    background: #3ba776;
    color: #fff;
  }

</style>
