var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"DescricoesTable",staticClass:"mt-3",attrs:{"fields":_vm.tableFields,"items":_vm.lote,"responsive":"","show-empty":"","empty-text":"Nenhum material adicionado"},scopedSlots:_vm._u([(!_vm.opmeConsignadoLote)?{key:"thead-top",fn:function(){return [_c('b-button',{staticClass:"add-descricao",attrs:{"active":true,"variant":"outline-secondary"},on:{"click":_vm.addCaixa}},[_vm._v("+ Adicionar descrição")])]},proxy:true}:{key:"custom-foot",fn:function(){return [_c('b-tr',[_c('b-td',{staticClass:"custom-foot-add-desc",attrs:{"colspan":4}},[_c('b-button',{staticClass:"add-descricao",attrs:{"active":true,"variant":"outline-secondary"},on:{"click":_vm.addCaixa}},[_vm._v("+ Adicionar descrição")])],1)],1)]},proxy:true},{key:"cell(patrimonio)",fn:function(ref){
var index = ref.index;
return [_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: false }),expression:"{ required: false }"}],key:("patrimonio_caixa-" + index),attrs:{"value":_vm.lote[index].patrimonio,"name":("patrimonio_caixa-" + index),"state":_vm.validateState(("patrimonio_caixa-" + index))},on:{"input":function (v) { return _vm.updateLote(index, 'patrimonio', v); }}})]}},{key:"cell(index)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.indexLessThanTen(index) ? ("0" + (index + 1)) : ("" + (index + 1)))+" ")]}},{key:"cell(descricao_caixas)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('InputWithSuggestions',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],key:("descricao_caixas-" + index),attrs:{"route":"material/materiaisPorDescricao","propertyName":"descricao","id":"descricao","name":("descricao_caixas-" + index),"value":_vm.lote[index].descricao_caixas,"state":_vm.validateState(("descricao_caixas-" + index)),"item-key-identifier":"id_material","item-content-identifier":"descricao","min-search-length":3,"max-item-length":30},on:{"input":function (v) { return _vm.updateLote(index, 'descricao_caixas', v); }}})],1)]}},{key:"cell(state)",fn:function(ref){
var index = ref.index;
return [_c('b-button',{staticClass:"btn btn-sm text--black",attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.removeMaterial(index)}}},[_c('trash-2-icon',{staticClass:"w20px"})],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }