<template>
  <div>
    <b-input-group class="time-picker-input">
      <b-form-input
        id="data_input"
        :name="name"
        :value="value"
        maxlength="5"
        type="text"
        :size="size"
        placeholder="hh:mm"
        @keypress="filterTeclas($event)"
        :disabled="isBusy"
        :state="state && stateMinuteInput"
        @input="handleChangeHorario"
        v-validate="'validateHourInput'"
      />
      <b-input-group-append>
        <b-form-timepicker
          :value="value"
          button-only
          right
          locale="pt-br"
          size="lg"
          name="data_input"
          no-close-button
          hide-header
          @input="handleChangeHorario"
        ></b-form-timepicker>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  inject: ['parentValidator'],
  created() {
    this.$validator = this.parentValidator;
  },
  data() {
    return {
      stateMinuteInput: null,
    };
  },
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: 'hour_input',
    },
    value: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: 'lg',
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        if (!v) return;
        // eslint-disable-next-line prefer-const
        let [h, m] = v.split(':');
        if (!h || !m) return;
        let needFix = false;
        if (h.length === 1) {
          needFix = true;
          h = `0${h}`;
        }
        if (m.length === 2) {
          this.stateMinuteInput = true;
        } else {
          this.stateMinuteInput = false;
        }
        if (needFix) {
          this.handleChangeHorario(`${h}:${m}`);
        }
      },
    },
  },
  methods: {
    filterTeclas($event) {
      if (
        $event.charCode === 0
        || /\d/.test(String.fromCharCode($event.charCode))
      ) {
        return true;
      }
      $event.preventDefault();
      return false;
    },
    handleChangeHorario(v) {
      const h = Number(v.substr(0, 2));
      const m = Number(v.substr(3, 2));

      let newValue;
      if (Number.isNaN(h) || h > 23) {
        newValue = '';
        return;
      }
      if (Number.isNaN(m) || m > 59) {
        newValue = '';
        return;
      }
      if (v.length > 5) {
        newValue = v.substr(0, 5);
      } else {
        newValue = v;
      }
      if (v.length === 2) {
        newValue = `${newValue}:`;
      }
      if (newValue.length >= 3 && newValue.substr(2, 1) !== ':') {
        newValue = '';
      }
      this.$emit('input', newValue);
    },
  },
};
</script>
